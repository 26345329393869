@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

#needle {
  transform: rotate(90deg);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
